import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Slideshow from "../components/slideshow"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import moment from "moment"

const BlogIndex = ({ data, pageContext, location }) => {
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    const previousLink = (pageContext.currentPage - 1) === 1
    ? `/`
    : `/page/`+(pageContext.currentPage - 1)
    const nextLink = `/page/`+(pageContext.currentPage + 1)
  
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          
          return (
            <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{moment(node.frontmatter.date).local().format(`MMMM DD, YYYY`)}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.html,
                  }}
                />
                <Slideshow images={node.frontmatter.images ? node.frontmatter.images : []} />
              </section>
            </article>
          )
        })}

        <ul style={{ paddingTop: '10px' }}>
          <span style={{float:'right'}}>{pageContext.currentPage < pageContext.numPages ? (<a href={nextLink}>Older Posts &gt;</a>) : ''}</span>
          {pageContext.currentPage > 1 ? (<a href={previousLink}>&lt; Newer Posts</a>) : ''}&nbsp;  
        </ul>
      </Layout>
    )
  
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPostList($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(      
      limit: $limit
      skip: $skip, 
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date
            title
            images {
              childImageSharp {
                fixed(width: 640, height: 480) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
